import { boot } from 'quasar/wrappers'
import { Quasar, Cookies } from 'quasar'
import { createI18n } from 'vue-i18n'

import {
  getLocaleFromRoutePath,
  getRoutePathWithLocale,
  getRouteNameWithLocale
} from 'src/utils/routesUtils.js';

const availableLocales = ['de', 'en-US', 'fr', 'it'];

export default boot(async ({ app, ssrContext, urlPath, redirect }) => {
  const urlLocale = getLocaleFromRoutePath(urlPath, availableLocales);

  let cookieLocale = null;
  if (!urlLocale) {
    const cookies = process.env.SERVER
      ? Cookies.parseSSR(ssrContext)
      : Cookies // otherwise we're on client
    cookieLocale = cookies.get("client_locale");
  }

  const locale = urlLocale || cookieLocale || 'en-US';

  const i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'en-US',
    globalInjection: true,
    availableLocales: availableLocales,
    // fallbackWarn: false,
    // warnHtmlMessage: false,
  });

  // Set i18n instance on app
  app.use(i18n)

  const _i18n = app.config.globalProperties.$i18n;

  app.config.globalProperties.$localePath = function (routePath, locale = null) {
    // prevent of falsy values
    if (!routePath) {
      return routePath;
    }

    // Assurez-vous que i18n est disponible
    if (!_i18n) {
      // throw new Error('i18n n’est pas disponible');
      console.error('i18n n’est pas disponible');
      return routePath;
    }

    locale = locale || _i18n.locale;

    if (typeof routePath === 'string') {
      // check if is hash
      if (routePath.startsWith('#')) {
        return routePath;
      }
      // check if is path or name
      if (routePath.startsWith('/')) {
        // path
        return getRoutePathWithLocale(routePath, locale);
      } else {
        // name
        return { name: getRouteNameWithLocale(routePath, locale) };
      }
    }

    if (typeof routePath === 'object') {
      // check if is path or name
      if (routePath.path) {
        // path
        routePath.path = getRoutePathWithLocale(routePath.path, locale);
      }
      if (routePath.name) {
        // name
        routePath.name = getRouteNameWithLocale(routePath.name, locale);
      }
      return routePath;
    }

    // prevent of any other type
    return routePath;
  }

  // Set Quasar Language Pack
  try {
    await import(
      /* webpackInclude: /(de|en-US|fr|it)\.js$/ */
      'quasar/lang/' + locale
    ).then(lang => {
      if (process.env.SERVER) Quasar.lang.set(lang.default, ssrContext)
      if (process.env.CLIENT) Quasar.lang.set(lang.default)
    })
  }
  catch (err) {
    // Requested Quasar Language Pack does not exist,
    // let's not break the app, so catching error
    if (process.env.SERVER) console.error(err)
  }

  if (cookieLocale) {
    redirect(getRoutePathWithLocale(urlPath, cookieLocale));
  }
})
