import { getLocaleFromRoutePath, getRoutePathWithLocale } from '../utils/routesUtils.js';

export function isErrorStatusCode(to, from, next) {
  const locale = getLocaleFromRoutePath(to.path, ['en-US', 'fr', 'de', 'it']) || '';
  const url404 = getRoutePathWithLocale('/error/404', locale);
  let errCode;
  if (process.env.MODE !== 'ssr') {
    if (!to.params.catchAll) {
      return next(url404);
    }
    if (!to.params.catchAll[0] && to.params.catchAll[0] !== 'error') {
      return next(url404);
    }
    errCode = to.params.catchAll[1] || 404
    to.params.code = errCode;
  } else {
    errCode = to.params.code || 404;
  }

  const code = parseInt(errCode, 10);
  if ((code >= 400 && code <= 418) ||
    (code >= 421 && code <= 426) ||
    code === 428 ||
    code === 429 ||
    code === 431 ||
    code === 451 ||
    (code >= 500 && code <= 508) ||
    (code >= 510 && code <= 511)) {
    return next(); // Continuer avec la route d'erreur si le code est valide
  } else {
    return next(url404);
  }
}

export default {
  isErrorStatusCode,
}
