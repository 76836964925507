import { isErrorStatusCode } from "./navigationGuards.js";
import { extendRoutesWithLocales } from "../utils/routesUtils.js";

const routes = [
  {
    path: "/",
    name: "index",
    // we use /src/layouts/User component which is imported above
    component: () => import("layouts/HeaderFooter.vue"),

    // hey, it has children routes and User has <router-view> in it;
    // It is really a Layout then!
    children: [
      {
        path: "",
        name: "home",
        component: () => import("pages/HomePage.vue"),
      },
      {
        path: "login",
        name: "login",
        component: () => import("pages/LoginPage.vue")
      },
      {
        path: "/register",
        name: "register",
        component: () => import("pages/RegisterPage.vue")
      },
      {
        path: "add",
        name: "add",
        component: () => import("pages/NewAnnoncesPage.vue"),
      },
      // { path: 'listings', name: 'listings', component: () => import('pages/ListingsPage.vue') },
      // { path: 'listing/:slug', name: 'listing-detail', component: () => import('pages/ListingDetailsPage.vue') },
      // { path: '/sellers', name: 'sellers', component: () => import('pages/SellersPage.vue') },
      // { path: '/seller/:slug', name: 'seller-profile', component: () => import('pages/SellerProfilePage.vue') },
      // {
      //   path: 'blog',
      //   name: 'blog',
      //   children: [
      //     { path: '', name: 'blog-home', component: () => import('pages/BlogPage.vue') },
      //     { path: ':slug', name: 'blog-article', component: () => import('pages/BlogArticlePage.vue') }
      //   ]
      // },
      // { path: 'contact', name: 'contact', component: () => import('pages/ContactPage.vue') },
      // { path: 'about', name: 'about', component: () => import('pages/AboutPage.vue') },
      // { path: 'terms', name: 'terms', component: () => import('pages/TermsPage.vue') },
      // { path: 'privacy', name: 'privacy', component: () => import('pages/PrivacyPage.vue') }
    ],
  },
  // {
  //   path: '/account',
  //   name: 'account',
  //   component: () => import('layouts/UserLayout.vue'),
  //   children: [
  //     { path: '', name: 'user-dashboard', component: () => import('pages/user/UserDashboardPage.vue') },
  //     { path: 'listings', name: 'user-listings', component: () => import('pages/user/UserListingsPage.vue') },
  //     {
  //       path: 'listings',
  //       name: 'user-listings-detail',
  //       children: [
  //         { path: '', name: 'user-listings-home', component: () => import('pages/UserListingsPage.vue') },
  //         { path: ':slug', name: 'user-listing-detail', component: () => import('pages/UserListingPage.vue') }
  //       ]
  //     },
  //     { path: 'searches', name: 'user-searches', component: () => import('pages/user/UserSearchesPage.vue') },
  //     { path: 'support', name: 'user-support', component: () => import('pages/user/UserSupportPage.vue') },
  //     { path: 'premium', name: 'user-premium', component: () => import('pages/user/UserPremiumPage.vue') },
  //     {
  //       path: 'vip',
  //       name: 'user-vip',
  //       beforeEnter: [isVipUser],
  //       children: [
  //         { path: 'favorites', name: 'user-favorites', component: () => import('pages/user/UserFavoritesPage.vue') },
  //         { path: 'statistics', name: 'user-statistics', component: () => import('pages/admin/AdminStatisticsPage.vue') },
  //         { path: 'new-listings', name: 'user-new-listings', component: () => import('pages/user/UserNewListingsPage.vue') },
  //       ]
  //     },
  //     { path: 'profile', name: 'user-profile', component: () => import('pages/user/UserProfilePage.vue') },
  //     { path: 'settings', name: 'user-settings', component: () => import('pages/user/UserSettingsPage.vue') },
  //   ]
  // },
  // {
  //   path: '/admin',
  //   name: 'admin',
  //   component: () => import('layouts/AdminLayout.vue'),
  //   children: [
  //     { path: '', name: 'admin-home', component: () => import('pages/admin/AdminHomePage.vue') },
  //     { path: 'listings', name: 'admin-listings', component: () => import('pages/admin/AdminListingsPage.vue') },
  //     { path: 'approve', name: 'admin-approve', component: () => import('pages/admin/ApprovePage.vue') },
  //     { path: 'tickets', name: 'admin-tickets', component: () => import('pages/admin/AdminTicketsPage.vue') },
  //     { path: 'users', name: 'admin-users', component: () => import('pages/admin/AdminUsersPage.vue') },
  //     { path: 'statistics', name: 'admin-statistics', component: () => import('pages/admin/AdminStatisticsPage.vue') },
  //     { path: 'settings', name: 'admin-settings', component: () => import('pages/admin/AdminSettingsPage.vue') }
  //   ]
  // },
];

if (process.env.PROD) {
  // Empty existing routes
  routes.length = 0;

  // Add InBuild Page
  routes.push({
    path: "/",
    name: "index",
    component: () => import("layouts/SimplifiedLayout.vue"),
    children: [
      {
        path: "",
        name: "landing",
        component: () => import("pages/LandingPage.vue"),
      },
    ],
  });
}

// Always leave this as last one errors pages
const errorPath = process.env.MODE === 'ssr' ? "/error/:code" : "/:catchAll(.*)*";
routes.push({
  path: errorPath,
  name: "catch-all",
  beforeEnter: [isErrorStatusCode],
  component: () => import("layouts/EmptyLayout.vue"),
  children: [
    {
      path: "",
      name: "error",
      component: () => import("pages/ErrorPage.vue"),
    },
  ],
});

const internationalisedRoutes = extendRoutesWithLocales(routes, 'en-US', ['en-US', 'fr', 'de', 'it']);

export default internationalisedRoutes;
