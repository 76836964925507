function getLocaleFromRoutePath(routePath, availableLocales) {
  let locale = availableLocales.find((subLocale) => {
    const pathSegment = `/${subLocale}`;
    return routePath.startsWith(pathSegment);
  });
  return locale || null;
}

function getRoutePathWithoutLocale(routePath, availableLocales) {
  let locale = getLocaleFromRoutePath(routePath, availableLocales);
  if (locale) {
    return routePath.replace(`/${locale}`, '');
  }
  return routePath;
}

function getRoutePathWithLocale(routePath, locale) {
  const cleanedRoutePath = getRoutePathWithoutLocale(routePath, ['en-US', 'fr', 'de', 'it']);
  if (locale) {
    if (locale === 'en-US') {
      return cleanedRoutePath;
    }
    return `/${locale}${cleanedRoutePath}`;
  } else {
    return cleanedRoutePath;
  }
}

function getLocaleFromRouteName(routeName, availableLocales) {
  if (!routeName) {
    throw new Error('Name is required on eatch route on the vue-router');
  }
  let locale = availableLocales.find((subLocale) => routeName.endsWith(`___${subLocale}`));
  return locale || null;
}

function getRouteNameWithoutLocale(routeName, availableLocales) {
  let locale = getLocaleFromRouteName(routeName, availableLocales);
  if (locale) {
    return routeName.replace(`___${locale}`, '');
  }
  return routeName;
}

function getRouteNameWithLocale(routeName, locale) {
  const cleanedRouteName = getRouteNameWithoutLocale(routeName, ['en-US', 'fr', 'de', 'it']);
  return `${cleanedRouteName}___${locale}`;
}

function localizeRoute(route, locale) {
  // Localiser le nom de la route courante
  const localizedRoute = {
    ...route,
    name: getRouteNameWithLocale(route.name, locale),
    // Appeler de manière récursive pour les enfants, s'ils existent
    children: route.children
      ? route.children.map(childRoute => localizeRoute(childRoute, locale))
      : []
  };

  return localizedRoute;
}

function extendRoutesWithLocales(routes, defaultLocale, supportedLocales) {
  const newRoutes = [];
  routes.forEach((route) => {
    supportedLocales.forEach((locale) => {
      let path = locale === defaultLocale
        ? route.path
        : (route.path ? `/${locale}${route.path === "/" ? '' : route.path}` : '');

      const localizedRoute = localizeRoute({ ...route, path: path }, locale);
      newRoutes.push(localizedRoute);
    });
  });

  return newRoutes;
}

export {
  getLocaleFromRoutePath,
  getRoutePathWithoutLocale,
  getRoutePathWithLocale,
  getLocaleFromRouteName,
  getRouteNameWithoutLocale,
  getRouteNameWithLocale,
  localizeRoute,
  extendRoutesWithLocales,
};
