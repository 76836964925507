<template>
  <router-view />
</template>

<script>
import { defineComponent } from "vue";
import { useMeta } from "quasar";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { getRoutePathWithLocale } from "src/utils/routesUtils.js";

export default defineComponent({
  name: "App",
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    const route = useRoute();
    useMeta(() => {
      return {
        title: "AutoMarket",
        meta: {
          description: {
            name: "description",
            content: "",
          },
          robots: { name: "robots", content: "index, follow" },
          author: { name: "author", content: "AutoMarket" },
          language: { name: "language", content: locale.value },
          ogTitle: {
            property: "og:title",
            content: "AutoMarket",
          },
          ogType: { property: "og:type", content: "website" },
          ogImage: { property: "og:image", content: "/icons/icon-512x512.png" },
          ogUrl: {
            property: "og:url",
            content: `${process.env.APP_URL}${route.path}`,
          },
          ogDescription: {
            property: "og:description",
            content: "",
          },
          ogSiteName: {
            property: "og:site_name",
            content: "AutoMarket",
          },
          ogLocale: {
            property: "og:locale",
            content: locale.value,
          },
          twitterCard: {
            name: "twitter:card",
            content: "summary_large_image",
          },
          // TODO: demander a anthony
          // twitterSite: {
          //   name: "twitter:site",
          //   content: "@autoMarket",
          // },
          twitterTitle: {
            name: "twitter:title",
            content: "AutoMarket",
          },
          twitterDescription: {
            name: "twitter:description",
            content: "",
          },
          twitterImage: {
            name: "twitter:image",
            content: "/icons/icon-512x512.png",
          },
          twitterUrl: {
            name: "twitter:url",
            content: `${process.env.APP_URL}${route.path}`,
          },
          // TODO: demander a anthony
          // twitterCreator: { name: "twitter:creator", content: "@autoMarket" },
        },
        link: {
          "en-US": {
            rel: "alternate",
            hreflang: "en-US",
            href: `${process.env.APP_URL}${getRoutePathWithLocale(
              route.path,
              "en-US"
            )}`,
          },
          fr: {
            rel: "alternate",
            hreflang: "fr",
            href: `${process.env.APP_URL}${getRoutePathWithLocale(
              route.path,
              "fr"
            )}`,
          },
          de: {
            rel: "alternate",
            hreflang: "de",
            href: `${process.env.APP_URL}${getRoutePathWithLocale(
              route.path,
              "de"
            )}`,
          },
          it: {
            rel: "alternate",
            hreflang: "it",
            href: `${process.env.APP_URL}${getRoutePathWithLocale(
              route.path,
              "it"
            )}`,
          },
          canonical: {
            rel: "canonical",
            href: `${process.env.APP_URL}${route.path}`,
          },
        },
        script: {
          ldJson: {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@graph": [
                {
                  "@type": "WebSite",
                  url: "https://automarket.ch/",
                  name: "AutoMarket",
                  about: {
                    "@type": "Thing",
                    name: "AutoMarket",
                  },
                  accountablePerson: {
                    "@type": "Person",
                    name: "AutoMarket",
                  },
                  abstract: "AutoMarket",
                  contributor: "Owapp",
                  // "audience": {
                  //   "@type": "Audience",
                  //   "audienceType": "B2C",
                  //   "geographicArea": {
                  //     "@type": "AdministrativeArea",
                  //     "name": "Switzerland",
                  //     "geoMidpoint": {
                  //       "@type": "GeoCoordinates",
                  //       "latitude": "46.818188",
                  //       "longitude": "8.227512"
                  //     }
                  //   }
                  // },
                  copyrightHolder: "Juicy Web Sarl",
                  // copyrightNotice:
                  //   "pour décrire les aspects du droit d'auteur de cette œuvre créative, indiquant idéalement le propriétaire des droits d'auteur de l'œuvre.",
                  copyrightYear: "2024",
                  // potentialAction: {
                  //   "@type": "SearchAction",
                  //   target:
                  //     "https://www.votresite.com/search?&q={search_term_string}",
                  //   "query-input": "required name=search_term_string",
                  // },
                  // sameAs: [
                  //   "https://www.facebook.com/votrepage",
                  //   "https://www.twitter.com/votrecompte",
                  //   // Autres profils de médias sociaux...
                  // ],
                  // Plus de propriétés...
                },
                {
                  "@type": "Organization",
                  name: "AutoMarket",
                  url: "https://automarket.ch/",
                  logo: {
                    "@type": "ImageObject",
                    url: "https://automarket.ch/icon-512x512.png",
                  },
                  // contactPoint: [
                  //   {
                  //     "@type": "ContactPoint",
                  //     telephone: "+33 1 23 45 67 89",
                  //     contactType: "customer service",
                  //     areaServed: "FR",
                  //     availableLanguage: ["French", "English"],
                  //   },
                  //   // Plus de points de contact...
                  // ],
                  // sameAs: [
                  //   "https://www.facebook.com/votrepage",
                  //   "https://www.twitter.com/votrecompte",
                  //   // Autres profils de médias sociaux...
                  // ],
                  founder: [
                    {
                      "@type": "Person",
                      name: "Anthony Couzin",
                      // Autres détails du fondateur...
                    },
                  ],
                  foundingDate: "2023-09-25",
                  // Plus de propriétés...
                },
                // Ajoutez ici d'autres entités comme Product, Article, Event, etc.
              ],
            },
          },
        },
      };
    });
  },
});
</script>
